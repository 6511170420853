import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../view/Home'),
    },
    {
        path: '/editor',
        name: 'editor',
        component: () => import('../view/GrapeJSEditor'),
    }
]

const router = new VueRouter({
    routes
})

export default router
