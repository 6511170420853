<template>
  <div id="app">
    <Navigation/>
  </div>
</template>

<script>
import Navigation from './components/navigation/Navigation'

export default {
  components: {
    Navigation
  }
}
</script>
